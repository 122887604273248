import React, {useEffect, useState, useRef} from 'react';
import ReactTooltip from 'react-tooltip'

import FirebaseManager from '../util/FirebaseManager';

import '../styles/containers/Current.css';
import { formatTimestamp } from '../util/helpers';

function Message({ isCurrentUser, snapshotKey, user, message }) {
  return (
    <div
      key={snapshotKey}
      className={`message ${isCurrentUser ? "message-right" : "message-left"}`}
    >
      <div
        className="message-text"
        data-tip={formatTimestamp(snapshotKey)}
        data-for={`${snapshotKey}:${user.ID}`}
      >
        {message}
      </div>
      <ReactTooltip
        id={`${snapshotKey}:${user.ID}`}
        place={isCurrentUser ? "left" : "right"}
        type="dark"
        effect="solid"
        key={`tooltip:${snapshotKey}:${user.ID}`}
      />
      <div className="message-user">
        <img className="img" src={user.imageURL} alt="" />
        <div className="name"> {user.displayName} </div>
      </div>
    </div>
  );
}

function Messages({ messages }) {
  const currentUserId = FirebaseManager.shared().auth.currentUser.uid;
  return messages.map(({ user, snapshotKey, message }) => (
    <Message
      isCurrentUser={user.ID === currentUserId}
      snapshotKey={snapshotKey}
      user={user}
      message={message}
    />
  ));
}

function Current(props) {
  const {stationInfo, stationID} = props;
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");

  const messagesEndRef = useRef(null);

  const sendMessage = (e) => {
    e.preventDefault();
    FirebaseManager.shared().sendMessageToLiveStation(stationID, text);
    setText("");
  }

  const scrollToBottom = () => {
    if (messagesEndRef) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const onKeyPress = (e) => {
    if(e.which === 13) {
      sendMessage(e);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setText(e.target.value);
  }

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    setMessages([]);
    const handle = FirebaseManager.shared().observeStationChats(stationID, (snapshot) => {
      if (!snapshot || !snapshot.exists()) return;

      const val = snapshot.val();
      if (!val || val.song) return;

      const newMessage = {
        user: val.user,
        snapshotKey: snapshot.key,
        message: val.message,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
    return () => FirebaseManager.shared().detachStationChats(stationID, handle);
  }, [stationID]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="current-container">
      <div className="current-title">
        <img className="img" src={stationInfo && stationInfo.dj ? stationInfo.dj.imageURL : ""} alt="" />
        <div className="text">LIVE CHAT</div>
      </div>
      <div className="content">
        <div className="chat-container">
          <div className="messages-container">
            {messages && messages.length > 0 ? <Messages messages={messages} /> : (<div className="empty-messages">No messages yet!</div>)}
            <div style={{ float:"left", clear: "both" }} ref={messagesEndRef}>
            </div>
          </div>
          <div className="input-container">
            <input name="display-name" placeholder="type message here" type="text" onKeyPress={onKeyPress} onChange={handleChange} value={text} autoComplete="off" />
          </div>
        </div>
        <div className="queue-container">
          
        </div>
      </div>
    </div>
  );
}

export default Current;
