// Copy config to digitalocean
// scp -r ./src/config.js service@161.35.60.44:~/webapp/src/

// DEV
// const config = {
//   API_URL: "http://0.0.0.0:5000",
//   SITE_URL: "http://localhost:3000",
//   LOG: true,
// };

// LIVE
const config = {
  API_URL: "https://api.wefm.live",
  SITE_URL: "https://wefm.live",
};

export default config;
