import React, {Component} from 'react';

import NowPlaying from '../components/NowPlaying.js';
import About from '../components/About';
import Modal from '../components/Modal';
import Live from './Live';
import Current from './Current';
import FirebaseManager from '../util/FirebaseManager';
import LiveManager from '../util/LiveManager.js';
import SpotifyManager from '../util/SpotifyManager';
import {checkForLinkedStation} from "../util/helpers";
import {refreshSpotifyToken} from '../util/wefmapi';

import logo from '../images/logo.png';
import icon from '../images/icon.png';
import '../styles/containers/Home.css';

class Home extends Component {
  constructor() {
    super();

    this.state = {
      showWebPlayerComponent: false,
      initalPlaybackTransfer: false,
      isActiveDevice: false,
      displayedContainer: "Browse",
      showConnect: true,
      loggedIn: false,
      connectedStation: "",
      currentStation: null,
      isLive: false,
      linkedStation: null,
      unreadMessages: 0
    };

    LiveManager.shared().myID = `${FirebaseManager.shared().auth.currentUser.uid}${Date.now()}`;

    // Spotify functions
    this.makeActivePlayer = this.makeActivePlayer.bind(this);
    this.checkForExistingDevice = this.checkForExistingDevice.bind(this);
    this.pause = this.pause.bind(this);

    this.getToken = this.getToken.bind(this);
    this.tokenExpiry = null;

    // Firebase functions
    this.logout = this.logout.bind(this);
    this.tuneIn = this.tuneIn.bind(this);
    this.goLive = this.goLive.bind(this);

    this.changeDisplay = this.changeDisplay.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  matchSong(curTrackID, prevPlayerState, curPlayerState) {
    if (prevPlayerState && curPlayerState) {
      return (curTrackID === prevPlayerState.track_window.current_track.uri ||
        curTrackID === curPlayerState.track_window.current_track.uri ||
        (curPlayerState.track_window.current_track.linked_from_uri && curTrackID === curPlayerState.track_window.current_track.linked_from_uri))
    }
    return true;
  }

  componentDidMount() {
    SpotifyManager.shared().updatePlayerState = (update) => {
      const {spotifyPlayerState, playerState, prevPlayerState} = update;

      if (spotifyPlayerState) {
        if (spotifyPlayerState === "ready") {
          this.setState(update, () => {
            this.makeActivePlayer();
          });
        } else {
          this.setState(update);
        }
      } else if (playerState) {
        this.setState({playerState: playerState, isActiveDevice: true});

        if (playerState && this.state.linkedStation && this.state.isActiveDevice) {
          this.tuneIn(this.state.linkedStation)
          FirebaseManager.shared().logShareActivity("open", this.state.linkedStation, {story: false});
          this.setState({linkedStation: null})
          window.history.pushState("", "", "/");
        }

        // Disconnect from station if song is different
        if (!LiveManager.shared().isLive()) {
          // if (LiveManager.shared().subscribedID) {
          //   var curStationTrackId = this.state.currentStation.track.playbackID
          //   if (!this.matchSong(curStationTrackId, prevPlayerState, playerState)) {
          //     console.log("Disconnecting - changed song from spotify")
          //     LiveManager.shared().disconnect()
          //     this.setState({connectedStation: "", currentStation: null, isLive: false, displayedContainer: "Browse"})
          //   }
          // }
          return
        }
        var curTrack = null;
        if (playerState && !playerState.paused) {
          const trackWindow = playerState.track_window;
          if (trackWindow && trackWindow.current_track) {
            curTrack = trackWindow.current_track;
          }
        }
        if (!curTrack) {
          return
        }

        if (!this.state.currentStation && LiveManager.shared().firstLive) {
          FirebaseManager.shared().updateStation(curTrack, playerState.position);
          return;
        }
        var prevTrack = null;
        if (prevPlayerState && prevPlayerState.track_window && prevPlayerState.track_window.current_track) {
          prevTrack = prevPlayerState.track_window.current_track;
        }
        if (!prevTrack || (prevTrack.uri === curTrack.uri && Math.abs(prevPlayerState.duration === playerState.duration) < 1000)) {
          FirebaseManager.shared().updateStation(curTrack, playerState.position);
        }
      } else {
        this.setState({playerState: null, isActiveDevice: false}, () => {
          if (this.state.isLive) {
            LiveManager.shared().disconnect();
            this.setState({
              connectedStation: "",
              currentStation: null,
              isLive: false,
              displayedContainer: "Browse"
            });
          }
        });
      }
    }

    this.setState({linkedStation: checkForLinkedStation(window.location.href)})
    this.getToken();
    FirebaseManager.shared().updateLastActive();

    // Monitor the current station, to keep track of multiple sessions open.
    FirebaseManager.shared().monitorCurrentStation((exists) => {
      if (!exists) {
        this.checkForExistingDevice()
      }
    })

    // If you were already live and the station never got removed when you left
    FirebaseManager.shared().checkForExistingStation((exists, broadcastID) => {
      if (broadcastID !== LiveManager.shared().myID && exists) {
        // TODO: handle existing station better.
        FirebaseManager.shared().removeStation(broadcastID);
      }
    })

    // If you were already live and the station never got removed when you left
    FirebaseManager.shared().checkForAlreadyListened((exists, broadcastID) => {
      if (broadcastID !== LiveManager.shared().myID && exists) {
        // TODO: handle existing listener better.
        FirebaseManager.shared().removeListenerFromStation(broadcastID);
      }
    })
  }

  componentWillUnmount() {
    if (this.state.connectedStation !== "") {
      LiveManager.shared().disconnect();
    }
  }

  getToken = () => {
    const params = this.getHashParams();
    const token = params.access_token;
    const expired = params.expires_in;
    if (token) {
      this.setState({
        token: token,
        expiresIn: expired,
        showConnect: token ? false : true,
        loggedIn: token ? true : false,
      })
      SpotifyManager.shared().setAccessToken(token);
      clearTimeout(this.tokenExpire);
      this.tokenExpire = setTimeout(() => this.getToken(), (expired - 120) * 1000);
      this.checkForExistingDevice()
    } else {
      const that = this;
      FirebaseManager.shared().auth.currentUser.getIdToken(true).then((idToken) => {
        refreshSpotifyToken(FirebaseManager.shared().auth.currentUser.uid, idToken, (data, err) => {
          if (data) {
            const token = data.access_token;
            const expired = data.expires_in;
            if (token) {
              that.setState({
                token: token,
                expiresIn: expired,
                showConnect: token ? false : true,
                loggedIn: token ? true : false,
              })
              SpotifyManager.shared().setAccessToken(token);
              clearTimeout(that.tokenExpire);
              that.tokenExpire = setTimeout(() => that.getToken(), (expired - 120) * 1000);
              that.checkForExistingDevice()
            }
          }
        });
      });
    }
  }

  getHashParams = () => {
    var hashParams = {};
    var e, r = /([^&;=]+)=?([^&;]*)/g,
      q = window.location.hash.substring(1);
    e = r.exec(q)
    while (e) {
      hashParams[e[1]] = decodeURIComponent(e[2]);
      e = r.exec(q);
    }
    if (window.history.pushState) {
      window.history.pushState('', '/', window.location.pathname)
    } else {
      window.location.hash = '';
    }
    return hashParams;
  }

  goLive = () => {
    if (!this.state.token) {
      alert("we:fm - It looks your spotify account is not connected, please connect your spotify.")
      return
    }
    if (!this.state.showWebPlayerComponent) {
      alert("we:fm - It looks like you might have multiple windows with wefm, please close any session that is not active. wefm works best when you only have one session open.")
      return
    }

    const startLive = () => {
      LiveManager.shared().goLive((update, prev) => {
        this.setState({
          connectedStation: LiveManager.shared().subscribedID,
          currentStation: update,
          isLive: true
        });
      });
      // this.observeCurrentChat(LiveManager.shared().myID);
      SpotifyManager.shared().getCurrentPlaybackState((response, error) => {
        if (response) {
          if (!response.is_playing) {
            SpotifyManager.shared().play({});
          } else {
            FirebaseManager.shared().updateStation(response.item, response.progress_ms);
          }
        }
      })
    }

    if (!this.state.isActiveDevice) {
      this.makeActivePlayer((error) => {
        if (error) {
          // TODO: Handle error here
          return
        }
        startLive();
      });
    } else {
      startLive();
    }
  }

  tuneIn = (stationID) => {
    if (!this.state.token) {
      alert("It looks your spotify account is not connected, please connect your spotify.")
      return
    }
    if (!this.state.showWebPlayerComponent) {
      alert("we:fm - It looks like you might have multiple windows open with wefm, please close any session that is not active. wefm works best when you only have one session open.")
      return
    }
    if (this.state.connectedStation === stationID) {
      return
    }
    // TODO: If live and have listeners, notify user.
    LiveManager.shared().connectTo(stationID, (current, prev) => {
      if (!current) {
        LiveManager.shared().disconnect();
        SpotifyManager.shared().pause();
        this.setState({connectedStation: "", currentStation: null, isLive: false, displayedContainer: "Browse"});
        alert("we:fm - Your current station has ended!");
        return
      }
      this.setState({connectedStation: stationID, currentStation: current, isLive: false});

      const trackInfo = current.track;
      if (!trackInfo || trackInfo.type !== "Spotify") {
        return
      }
      if (prev && JSON.stringify(prev.track) === JSON.stringify(trackInfo)) {
        return
      }

      console.log("Track Changed: ", current);

      const uri = trackInfo.playbackID;
      SpotifyManager.shared().play({"uris": [uri]}, (error) => {
        if (!error) {
          const seekTo = parseInt(Date.now() - (1000 * current.lastTrackStarted) + 100, 10);
          SpotifyManager.shared().seek(seekTo);
        } else {
          SpotifyManager.shared().play({"uris": [uri], "device_id": this.state.userDeviceId}, (error) => {
            if (!error) {
              const seekTo = parseInt(Date.now() - (1000 * current.lastTrackStarted) + 100, 10);
              SpotifyManager.shared().seek(seekTo);
            } else {
              // TODO: Manage error
            }
          });
        }
      });
    })
    // this.observeCurrentChat(stationID);
  }

  // observeCurrentChat = (stationID) => {
  //   //  For chat notifications
  //   this.setState({unreadMessages: 0});
  //   FirebaseManager.shared().observeStationChats(stationID, (snapshot) => {
  //     if (!snapshot || !snapshot.exists()) {
  //       return;
  //     }
  //     const val = snapshot.val();
  //     if (!val || val.song) {
  //       return;
  //     }
  //     if (this.state.displayedContainer !== "Chat" && snapshot.key >= (Date.now() - 10000)) {
  //       this.setState({unreadMessages: this.state.unreadMessages + 1})
  //     }
  //   })
  // }

  logout = () => {
    LiveManager.shared().disconnect();
    FirebaseManager.shared().auth.signOut().then(() => {
      LiveManager.shared().firstLive = false;
      SpotifyManager.shared().pause();
    });
  }

  makeActivePlayer = (callback) => {
    if (this.state.userDeviceId) {
      SpotifyManager.shared().transferPlayback(this.state.userDeviceId, (error) => {
        if (!error) {
          this.setState({spotifyPlayerState: "selected", isActiveDevice: true});
        }
        if (callback) {
          callback(error);
        }
      });
    }
  }

  checkForExistingDevice = () => {
    SpotifyManager.shared().getDevices((response, error) => {
      if (!response || !response.devices) {
        this.setState({showWebPlayerComponent: true}, () => {
          if (this.state.token) {
            SpotifyManager.shared().initPlayer();
          }
        });
        return
      }
      const devices = response.devices;
      for (var i in devices) {
        if (devices[i].name === SpotifyManager.shared().playerName && devices[i].is_active && devices[i].id !== this.state.userDeviceId) {
          this.setState({showWebPlayerComponent: false})
          return
        }
      }
      this.setState({showWebPlayerComponent: true}, () => {
        if (this.state.token) {
          SpotifyManager.shared().initPlayer();
        }
      });
    })
  }

  pause = () => {
    const state = LiveManager.shared().state();
    if (state === "listener" || state === "dj") {
      LiveManager.shared().disconnect();
      this.setState({connectedStation: "", currentStation: null, isLive: false, displayedContainer: "Browse"});
    }
    SpotifyManager.shared().getCurrentPlaybackState((response, error) => {
      if (response) {
        if (response.is_playing) {
          SpotifyManager.shared().pause();
        } else {
          SpotifyManager.shared().play();
        }
      }
    })
  }

  changeDisplay = (id, e) => {
    e.preventDefault();
    this.setState({displayedContainer: id});
    if (id === "Chat" && this.state.unreadMessages) {
      this.setState({unreadMessages: 0});
    }
  }

  hideModal = (e) => {
    if (e.target.id === "neg-space" || e.target.id === "close-modal") {
      this.changeDisplay("Browse", e);
    }
  }

  render() {
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
    if (isSafari) {
      return <Modal
        contents={<div className="multiple-windows"> we:fm web currently only supports Chrome, Firefox, IE 11 or above,
          and Microsoft Edge. We are working on adding support for all other browsers! </div>} hideModal={() => {
      }}/>
    }

    var headerOptions = ["Browse", "About"];
    // if (this.state.connectedStation) {
    //   headerOptions = ["Chat", ...headerOptions];
    // }
    var headers = headerOptions.map(option => {
      var selected = this.state.displayedContainer === option ? "selected" : "";
      // var newMessages = this.state.unreadMessages > 0 && option == "Chat" ? "unread-chat" : "";
      // var enrichedOption = (option == "Chat" && this.state.unreadMessages > 0) ?
      return (
        <div
          key={option}
          className={"header-option-container " + selected}
          onClick={(e) => {
            this.changeDisplay(option, e)
          }}>
          <span> {option} </span>
        </div>
      );
    });

    var content = null;
    if (this.state.displayedContainer === "Browse") {
      content = (<Live tuneIn={this.tuneIn} connectedStation={this.state.connectedStation}/>);
    }
      // else if (this.state.displayedContainer === "Chat") {
      //   content = (<Current stationInfo={this.state.currentStation} stationID={this.state.connectedStation}/>);
    // }
    else if (this.state.displayedContainer === "About") {
      content = (<Modal contents={<About/>} hideModal={this.hideModal}/>)
    }

    var chat = null;
    if (this.state.connectedStation) {
      chat = (
        <div className={"chat chat-" + LiveManager.shared().state()}>
          <Current stationInfo={this.state.currentStation} stationID={this.state.connectedStation}/>
        </div>
      )
    }

    return (
      <div className="home">
        {this.state.displayedContainer === "About" ? content : null}
        {/* <div className="sidenav">
          <img src={icon} className="wefm-icon" alt="" />
          <div className="sidenav-options-container">
          </div>
        </div> */}
        {this.state.showWebPlayerComponent || !this.state.token ?
          <NowPlaying
            showConnect={this.state.showConnect}
            playerState={this.state.playerState}
            currentStation={this.state.currentStation}
            makeActivePlayer={this.makeActivePlayer}
            pauseClicked={this.pause}
            isLive={this.state.isLive}
            goLive={this.goLive}
          /> :
          <Modal contents={<div className="multiple-windows"> You have multiple windows open with wefm. Please only use
            one. </div>} hideModal={() => {
          }}/>
        }
        <div className="header">
          <div className="header-container">
            <div className="header-left-container">
              <div className="vertical-stack">
                <img src={icon} className="wefm-icon" alt=""/>
                <img src={logo} className="wefm-logo" alt=""/>
              </div>
            </div>
            <div className="header-center-container">
              {headers}
            </div>
            <div className="header-right-container">
              <button className="logout" onClick={this.logout}> Logout</button>
            </div>
          </div>
        </div>
        <div className={"main " + (this.state.connectedStation ? "chat-showing" : "")}>
          {this.state.displayedContainer !== "About" ? content : null}
        </div>
        {chat}
      </div>
    );
  }
}

export default Home;
