import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip'
import CopyToClipboard from 'react-copy-to-clipboard';

import FirebaseManager from '../util/FirebaseManager';

import inner from '../images/inner.png';
import outer from '../images/outer.png';
import share from '../images/share.png';
import '../styles/components/Station.css';
import config from '../config';
import { getColor, isPlaceholderAvatarUrl } from '../util/helpers';

function Listener({ displayName, stationID, ID, imageURL }) {
  return (
    <>
      <img
        className="img"
        data-tip={displayName}
        data-for={`${stationID}:${ID}`}
        src={imageURL}
        alt=""
        key={`listener:${ID}`}
      />
      <ReactTooltip
        id={`${stationID}:${ID}`}
        place="top"
        type="dark"
        effect="solid"
        key={`tooltip:${ID}`}
      />
    </>
  );
}

function Listeners({ users, numListeners, stationID }) {
  return (
    <>
      {users.map((user, i) => (
        <Listener {...user} stationID={stationID} key={`listener-${i}`} />
      ))}
      {numListeners > 4 && users.length == 4 ? (
        <div className="text">+{numListeners - 4}</div>
      ) : null}
    </>
  );
}

function Station(props) {
  const {stationID, info, tuneIn, connectedStation} = props;

  const [albumArtworkURL, setAlbumArtworkURL] = useState("");
  const [trackName, setTrackName] = useState("");
  const [artistName, setArtistName] = useState("");
  const [djName, setDjName] = useState("");
  const [djImageURL, setDjImageURL] = useState("");
  const [progress, setProgress] = useState(0);
  const [listeners, setListeners] = useState([]);

  useEffect(() => {
    FirebaseManager.shared().observeListeners(stationID, (stationListeners) => {
      console.log("StationListeners: ", stationListeners);
      if (!stationListeners) {
        setListeners([]);
        return;
      }
      const listeners = Object.values(stationListeners)
        .filter((u) => u.imageURL !== 'undefined')
        .sort((u1, u2) => {
          const isU1Placeholder = isPlaceholderAvatarUrl(u1.imageURL);
          const isU2Placeholder = isPlaceholderAvatarUrl(u2.imageURL);
          if (isU1Placeholder && isU2Placeholder) {
            return 0;
          } else if (isU1Placeholder) {
            return 1;
          } else {
            return -1;
          }
        })
        .slice(0, 4);
      setListeners(listeners);
    });
    return () => FirebaseManager.shared().detachObserveListeners(stationID);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const trackInfo = info.track;
    if (trackInfo.albumArtworkURL != null) {
      setAlbumArtworkURL(trackInfo.albumArtworkURL);
    }
    if (trackInfo.trackName != null) {
      setTrackName(trackInfo.trackName);
    }
    if (trackInfo.artistName != null) {
      setArtistName(trackInfo.artistName);
    }
    const userInfo = info.dj;
    if (userInfo.displayName != null) {
      setDjName(userInfo.displayName);
    }
    if (userInfo.imageURL != null) {
      setDjImageURL(userInfo.imageURL);
    }
    const timer = setInterval(() => {
      if (!info.lastTrackStarted) {
        return;
      }
      const trackInfo = info.track;
      const lastTrackStarted = info.lastTrackStarted;
      const playbackDuration = trackInfo.playbackDuration;
      setProgress(100-(((Date.now()-(1000*lastTrackStarted))/playbackDuration)*100));
    }, 1000);
    return () => clearInterval(timer);
  },[stationID, info]);

  const handleClicked = (e) => {
    e.preventDefault();
    if (e.target.className !== "share-img") {
      if (stationID.includes(FirebaseManager.shared().auth.currentUser.uid)) {
        alert("we:fm - Cannot join your own station!")
        return;
      }
      tuneIn(stationID);
    }
  }

  const getShareLink = () => {
    FirebaseManager.shared().logShareActivity("invite", stationID, {});
    alert('we:fm - Link copied to clipboard!');
  }

  return(
    <div className={`station ${connectedStation === stationID ? `station-${getColor()}` : "station-default"}`} onClick={handleClicked}>
      <div className="dj">
        <img className="img" data-tip={djName} data-for={`${stationID}:dj`} src={djImageURL} alt="" />
        <ReactTooltip id={`${stationID}:dj`} place="top" type="dark" effect="solid"/>
        <img className="inner" src={inner} alt="" />
        <img className="outer" src={outer} alt="" />
        <Listeners users={listeners} numListeners={Math.abs(info.numListeners)} stationID={stationID} />
      </div>
      <img className="album-img" src={albumArtworkURL} alt="" />
      <div className="track-info">
        <div className="track-names-container">
          <div className="track-names">
            <div className="track-name"> {trackName} </div>
            <div className="artist-name"> {artistName} </div>
          </div>
          <CopyToClipboard text={`${config.SITE_URL}/stations/${stationID}`} onCopy={getShareLink}>
            <img className="share-img" src={share} alt="" />
          </CopyToClipboard>
        </div>
        <div className="progress-bar">
          <div className="middle-align progress-bar__bg">
            <div className="progress-bar__fg_wrapper">
              <div className="progress-bar__fg" style={{transform: `translateX(-${progress}%)`}}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Station;
