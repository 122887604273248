import config from '../config';

export function removeInactiveStation(stationID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ stationID: stationID })
  };
  fetch(`${config.API_URL}/inactive`, requestOptions).then(response => response.json());
}


export function refreshSpotifyToken(uid, authToken, callback) {
  const params = `token=${authToken}&uid=${uid}`
  fetch(`${config.API_URL}/spotify-refresh?${params}`).then(response => response.json())
  .then((response) => {
    if (callback) { callback(response, null) }
  })
  .catch((error) => {
    if (callback) { callback(null, error) }
  })
}

export function updateFanFeeds(stationID, uid) {
  if (!stationID || !uid) return;
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid,
      stationID: stationID
    })
  };
  fetch(`${config.API_URL}/feed`, requestOptions).then(response => response.json());
}