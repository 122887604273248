import React from 'react';

import '../styles/components/Modal.css';

function Modal(props) {
  const { hideModal, contents } = props;
  return (
    <div className="inapp-modal" onClick={hideModal} id="neg-space">
      <div className="inapp-modal-main">
        <div className="inapp-close-modal" onClick={hideModal} id="close-modal">X</div>
        {contents}
      </div>
    </div>
  );
}

export default Modal;