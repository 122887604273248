import React from 'react';

import logo from '../images/logo.png';
import '../styles/components/About.css';

function About() {

  return (
    <div className="instruction-container">
      <img src={logo} className="wefm-logo" alt="" />
      <div className="team"> Brought to you by 
        <a href="https://www.linkedin.com/in/sebastien-goddijn-b8877263/" target="_blank" rel="noopener noreferrer">Seb</a>, 
        <a href="https://twitter.com/Midaskwant" target="_blank" rel="noopener noreferrer">Midas</a>, and 
        <a href="https://twitter.com/rchalla769" target="_blank" rel="noopener noreferrer">Rohan</a> 
      </div>
      <div className="section">
        Welcome to the beta version of we:fm's webapp. We hope you enjoy our app and we are working on adding more features.
        <p> Currently, you will need a Spotify Premium account to be able to use our platform.</p>
        <p className="section-header"> How to use</p>
        <p> To listen with another person, click on their station in the Browse tab.</p>
        <p> To create your station, make sure you are not tuned into another station (hit the stop button in the bottom bar) and then click "Go Live".
            Currently, the only way to change your music when you are live is to use:</p>
        <ul>
          <li>the spotify desktop app</li>
          <li><a href="https://open.spotify.com/" target="_blank" rel="noopener noreferrer">spotify website</a></li>
          <li>the spotify mobile app</li>
        </ul>
        <p> Make sure that the selected device is wefm Web Player, opening this website should automatically do this for you. Looking at your spotify app, you should have a green bar saying "Listening on wefm Web Player".</p>
        <p> If you encounter any issues or want a feature added, please email <a href="mailto:rchalla769@gmail.com" style={{color: "white"}} target="_blank" rel="noopener noreferrer">rchalla769@gmail.com</a></p>
      </div>
    </div>
  );
}

export default About;