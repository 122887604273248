import React, { useState } from 'react';
import ReactRotatingText from 'react-rotating-text';

import firebase from "firebase/app";
import FirebaseManager from '../util/FirebaseManager';
import { validateEmail, isMobile, getPlaceholderAvatarUrl } from '../util/helpers';
import Modal from '../components/Modal';

import logo from '../images/logo.png';
import appStore from '../images/appStore.png';
import fblogin from '../images/fblogin.png';
import continueBtn from '../images/continueBtn.png';
import emailLogin from '../images/email.png';
import '../styles/containers/Landing.css';

const listenWith = [
  "your friends",
  "your family",
  "your teammates",
  "your neighbors",
  "your college friends",
  "your coworkers",
  "your roommates",
  "your gym partners",
  "your squad",
  "your bois",
  "your girlz",
  "your favorite artist",
  "your fans",
  "your teacher",
  "your icon",
  "yourself",
  "your professor",
  "your people",
  "your high school friends",
  "that one hot dude at the gym",
  "your girlfriend",
  "your boyfriend",
  "your siblings",
  "that one hot girl at the gym"
];

function Landing() {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [displayNameErr, setDisplayNameErr] = useState(null);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(null);
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(null);
  const [signInErr, setSignInErr] = useState(null);

  const mobile = isMobile();
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari'].pushNotification));
  if (isSafari && !isMobile) {
    return <Modal contents={<div className="multiple-windows"> we:fm web currently only supports Chrome, Firefox, IE 11 or above, and Microsoft Edge. We are working on adding support for all other browsers! </div>} hideModal={() => {}} />
  }

  const canContinue = () => {
    if (modalType === "no-account") {
      if (emailErr || passwordErr || displayNameErr || !email || !password || !displayName) {
        return false
      }
    }
    else if (modalType === "existing-account") {
      if (emailErr || passwordErr || !email || !password) {
        return false
      }
    }
    return true
  }

  const loginWithFacebook = (e) => {
    e.preventDefault();
    const provider = new firebase.auth.FacebookAuthProvider();
    provider.addScope("email");
    provider.addScope("public_profile");
    FirebaseManager.shared().auth.signInWithRedirect(provider);
  }

  const signUpWithEmail = (e) => {
    e.preventDefault();
    FirebaseManager.shared().auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        FirebaseManager.shared().auth.currentUser.updateProfile({displayName: displayName}).then((value) => {
          FirebaseManager.shared().updateUserInfo(getPlaceholderAvatarUrl());
        });
      })
      .catch((error) => {
        setSignInErr(error.message);
      });
  }

  const loginWithEmail = (e) => {
    e.preventDefault();
    FirebaseManager.shared().auth.signInWithEmailAndPassword(email, password)
      .catch((error) => {
        setSignInErr(error.message);
      });
  }

  const emailButtonClicked = (e) => {
    e.preventDefault();
    setShowModal(true);
    setModalType("email-login");
  }

  var modal = null;
  if (showModal) {
    const hideModal = (e) => {
      e.preventDefault();
      if (e.target.id === "neg-space" || e.target.id === "close-modal") {
        setShowModal(false);
        setModalType("");
      }
    }

    const handleChange = (e) => {
      if (e.target.name === "email") {
        if (e.target.value.length > 0 && !validateEmail(e.target.value)) {
          setEmailErr("Invalid email")
        } else {
          setEmailErr(null)
        }
        setEmail(e.target.value);
      }
      else if (e.target.name === "password") {
        if (e.target.value.length > 0 && e.target.value.length < 8) {
          setPasswordErr("Password must have at least 8 characters")
        } else {
          setPasswordErr(null)
        }
        setPassword(e.target.value);
      }
      else if (e.target.name === "display-name") {
        if (e.target.value.length > 0 && e.target.value.length < 4) {
          setDisplayNameErr("Display name must have at least 4 characters")
        } else {
          setDisplayNameErr(null)
        }
        setDisplayName(e.target.value);
      }
    }

    const switchModalType = (e) => {
      e.preventDefault();
      if (e.target.id === "no-account") {
        setModalType("email-signup");
      }
      else if (e.target.id === "existing-account") {
        setModalType("email-login");
      }
      setSignInErr(null);
    }

    var signinErr = null;
    if (signInErr) {
      signinErr = <span className="input-err">{signInErr}</span>
    }
    var contents = null;
    if (modalType === "email-login") {
      contents = (
        <div className="modal-contents">
          <div className="title">Login</div>
          <div className="subtitle">{signinErr}</div>
          <div className="input-title">
            EMAIL{emailErr ? ":" : ""} <span className="input-err">{emailErr}</span>
          </div>
          <input name="email" placeholder="me@example.com" type="email" onChange={handleChange} value={email} />
          <div className="input-title">
            PASSWORD{passwordErr ? ":" : ""} <span className="input-err">{passwordErr}</span>
          </div>
          <input name="password" placeholder="*********" type="password" onChange={handleChange} value={password} />
          <div className="buttons">
            <button className="button" onClick={loginWithEmail} disabled={!canContinue()}>
              <img className="button-img" src={continueBtn} alt="Continue" />
            </button>
            <div className="or-text">or</div>
            {/* TODO: replace these images with html and css  */}
            <button className="button" onClick={loginWithFacebook}>
              <img className="button-img" src={fblogin} alt="Continue with Facebook" />
            </button>
          </div>
          <div id="no-account" className="footer" onClick={switchModalType}>Don't have an account?</div>
        </div>
      );
    }
    else if (modalType === "email-signup") {
      contents = (
        <div className="modal-contents">
          <div className="title">Signup</div>
          <div className="subtitle">{signinErr}</div>
          <div className="input-title">
            DISPLAY NAME{displayNameErr ? ":" : ""} <span className="input-err">{displayNameErr}</span>
          </div>
          <input name="display-name" placeholder="first last" type="text" onChange={handleChange} value={displayName} />
          <div className="input-title">
            EMAIL{emailErr ? ":" : ""} <span className="input-err">{emailErr}</span>
          </div>
          <input name="email" placeholder="me@example.com" type="email" onChange={handleChange} value={email} />
          <div className="input-title">
            PASSWORD{passwordErr ? ":" : ""} <span className="input-err">{passwordErr}</span>
          </div>
          <input name="password" placeholder="*********" type="password" onChange={handleChange} value={password} />
          <div className="buttons">
            <button className="button" onClick={signUpWithEmail} disabled={!canContinue()}>
              <img className="button-img" src={continueBtn} alt="Continue" />
            </button>
            <div className="or-text">or</div>
            {/* TODO: replace these images with html and css */}
            <button className="button" onClick={loginWithFacebook}>
              <img className="button-img" src={fblogin} alt="Continue with Facebook" />
            </button>
          </div>
          <div id="existing-account" className="footer" onClick={switchModalType}>Already have an account?</div>
        </div>
      );
    }
    modal = (
      <div className="modal" onClick={hideModal} id="neg-space">
        <div className="modal-main">
          <div className="close-modal" onClick={hideModal} id="close-modal">X</div>
          {contents}
        </div>
      </div>
    );
  }

  return (
    <div className="landing">
      {modal}
      <div className="teaser">
        <img className="icon" src={logo} alt="logo" />
        <div className="listen-with">
          <span className="listen-with-elem listen-with-1 right">listen with</span>
          <span className="listen-with-elem listen-with-2 center">:</span>
          <span className="listen-with-elem listen-with-3 left">
            <ReactRotatingText items={listenWith} />
          </span>
        </div>
        {mobile ? (
          <div className="buttons">
            <a className="button" rel="noopener noreferrer" href="https://apps.apple.com/us/app/wefm/id1327177006?ls=1" target="_blank">
              <img className="button-img" src={appStore} alt="App Store" />
            </a>
          </div>
         ) : (
          <div className="buttons">
           <button className="button" onClick={loginWithFacebook}>
              <img className="button-img" src={fblogin} alt="Continue with Facebook" />
            </button>
            <button className="button" onClick={emailButtonClicked}>
              <img className="button-img" src={emailLogin} alt="Continue with Email" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Landing;
