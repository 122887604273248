import React, { useState, useEffect } from 'react';

import Home from './containers/Home';
import Landing from './containers/Landing';
import FirebaseManager from './util/FirebaseManager';

import './styles/App.css';

function Splash() {
  return (
    <div className="mt-8">
      YOOOOO
    </div>
  );
}

function App() {
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState();

  FirebaseManager.shared().auth.getRedirectResult().then(function(result) {
    if (result.credential) {
      const fbData = result.user.providerData;
      if (fbData.length > 0) {
        FirebaseManager.shared().updateFBTable(fbData[0].uid)
        FirebaseManager.shared().updateEmail(fbData[0].email)
        if (fbData[0].photoURL) {
          const imgURL = `${fbData[0].photoURL}/?width=250&height=250`;
          FirebaseManager.shared().updateUserInfo(imgURL)
        }
      }
    }
  }).catch(function(error) {
    console.log("Auth Error: ", error);
    if (error.code === 'auth/account-exists-with-different-credential') {
      alert("we:fm - It looks like your account was created using an email/password. Please continue appropriately.")
    }
  })

  useEffect(() => {
    // Handle user state changes
    const onAuthStateChanged = (user) => {
      setUser(user);
      if (initializing) setInitializing(false);
    }
    // Return the subscriber to unsubscribe on unmount.
    return FirebaseManager.shared().auth.onAuthStateChanged(onAuthStateChanged);
  }, [initializing, setInitializing]);

  if (initializing) return (<Splash />);

  return (
    <div className="App">
      { user ? <Home /> : <Landing /> }
    </div>
  );
}

export default App;
