import FirebaseManager from "./FirebaseManager";
import { updateFanFeeds } from "./wefmapi";

export default class LiveManager {
  static Shared = null;
  static shared() {
    if (LiveManager.Shared == null) {
      LiveManager.Shared = new LiveManager();
    }
    return this.Shared;
  }

  myID = null;
  subscribedID = null;
  isPrivate = false;

  firstLive = true;

  isLive() {
    return this.myID !== null && this.subscribedID !== null && this.myID === this.subscribedID;
  }

  state() {
    if (this.isPrivate) {
      return "private"
    }
    if (this.isLive()) {
        return "dj"
    }
    if (this.subscribedID == null) {
        return null
    }
    if (!this.isLive() && !this.isPrivate) {
        return "listener"
    }
    return null
  }

  goPrivate() {
    this.disconnect()
    this.isPrivate = true
  }

  goLive(onUpdate) {
    this.disconnect()
    this.subscribe(this.myID, onUpdate)
    updateFanFeeds(this.myID, FirebaseManager.shared().auth.currentUser.uid)
    this.firstLive = true
  }

  connectTo(stationID, onUpdate) {
    if (stationID !== this.subscribedID) {
      this.disconnect()
      this.subscribe(stationID, onUpdate)
      FirebaseManager.shared().addListenerToStation(stationID)
    }
  }

  subscribe(id, onUpdate) {
    this.subscribedID = id
    this.observeStation(onUpdate)
    this.isPrivate = false
  }

  unsubscribe() {
    FirebaseManager.shared().disconnectFromStation(this.subscribedID)
    FirebaseManager.shared().detachStationChats(this.subscribedID)
    this.subscribedID = null
  }
  
  disconnect() {
    console.log("Disconnecting: "+ this.subscribedID);
    if (this.isLive()) {
      FirebaseManager.shared().removeStation(this.subscribedID)
    }
    else if (this.subscribedID !== null) {
      FirebaseManager.shared().removeListenerFromStation(this.subscribedID);
    }
    this.unsubscribe()
  }

  observeStation(onUpdate) {
    if (this.subscribedID) {
      FirebaseManager.shared().connectToStation(this.subscribedID, (snap, prevSnap) => {
        if (!snap || !snap.exists() || !snap.val()) {
          if (onUpdate) {
            onUpdate(null, null);
          }
          return
        }
        if (onUpdate) {
          if (!prevSnap || !prevSnap.exists() || !prevSnap.val()) {
            onUpdate(snap.val(), null);
          } else{
            onUpdate(snap.val(), prevSnap.val());
          }
        }
      })
    } 
  }
}