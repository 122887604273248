import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import FirebaseManager from '../util/FirebaseManager';
import LiveManager from '../util/LiveManager';
import SpotifyManager from '../util/SpotifyManager';
import { getColor } from '../util/helpers';
import config from '../config';

import next from '../images/next.png';
import pause from '../images/pause.png';
import play from '../images/play.png';
import stop from '../images/stop.png';
import share from '../images/share.png';
import previous from '../images/previous.png';
import '../styles/components/NowPlaying.css';

function NowPlaying(props) {
  const {makeActivePlayer, playerState, currentStation, showConnect, isLive, pauseClicked, goLive} = props;

  var imageURL = "";
  var trackName = "";
  var artistName = "";

  var pauseImg = pause;
  if (isLive || currentStation) {
    pauseImg = stop;
  }

  if (playerState) {
    if (playerState.track_window != null) {
      if (playerState.track_window.current_track != null) {
        let currentTrack = playerState.track_window.current_track;
        if (currentTrack.name != null) {
          trackName = currentTrack.name;
        }
        if (currentTrack.artists != null) {
          for (var i in currentTrack.artists) {
            artistName += currentTrack.artists[i].name + ", "
          }
          artistName = artistName.substring(0, artistName.length-2);
        }
        if (currentTrack.album != null) {
          imageURL = currentTrack.album.images[0].url;
        }
      }
    }
    if (playerState.paused) {
      pauseImg = play;
    }
  }

  const handleClicked = (e) => {
    e.preventDefault();
    if (e.target.name === "previous") {
      SpotifyManager.shared().skipPrevious();
    }
    else if (e.target.name === "pause") {
      pauseClicked()
    }
    else if (e.target.name === "next") {
      SpotifyManager.shared().skipNext();
    }
  }

  const getShareLink = () => {
    FirebaseManager.shared().logShareActivity("invite", LiveManager.shared().myID, {});
    alert('we:fm - Link copied to clipboard!');
  }

  var connect = [];
  var playback = [];
  if (showConnect) {
    connect = (
      <a href={`${config.API_URL}/spotify-auth?uid=${FirebaseManager.shared().auth.currentUser.uid}`}>Connect Spotify</a>
    );
  } else {
    connect = (
      <button onClick={() => makeActivePlayer()}>Make this primary player</button>
    );
    const showPrevAndNext = LiveManager.shared().state() !== "listener";
    const showShareBtn = LiveManager.shared().state() === "dj" || LiveManager.shared().state() === "listener";
    playback = (
      <div className="playback-buttons-container">
        <div className="playback-buttons">
          {showPrevAndNext ? <img name="previous" onClick={handleClicked} src={previous} className="playback-button" alt="" /> : null}
          <img name="pause" onClick={handleClicked} src={pauseImg} className="playback-button" alt="" />
          {showPrevAndNext ? <img name="next" onClick={handleClicked} src={next} className="playback-button" alt="" /> : null}
          {showShareBtn ? 
            (<CopyToClipboard text={`${config.SITE_URL}/stations/${LiveManager.shared().subscribedID}`} onCopy={getShareLink}>
              <img className="playback-button" src={share} alt="" />
            </CopyToClipboard>)  : null
          }
        </div>
      </div>
    );
  }

  var trackInfoContainer = (
    <div className="track-info-container">{connect}</div>
  );
  if (imageURL !== null && imageURL !== "") {
    trackInfoContainer = (
      <div className="track-info-container">
        <img src={imageURL} alt="" />
        <div className="track-info">
          <div className="track-name"> {trackName} </div>
          <div className="ellipsis artist-name"> {artistName} </div>
        </div>
      </div>
    );
  }

  var djContainer = null;
  var goLiveContainer = null;

  if (currentStation && currentStation.dj) {
    var displayName = "";
    var djImageURL = "";

    const userInfo = currentStation["dj"];
    if (userInfo["displayName"] != null) {
      displayName = userInfo["displayName"];
    }
    if (userInfo["imageURL"] != null) {
      djImageURL = userInfo["imageURL"];
    }

    if (isLive) {
      djContainer = (
        <div className="dj">
          <img src={djImageURL} alt="" /> You are live!
        </div>
      );
    } else {
      djContainer = (
        <div className="dj">
          Tuned into: <img src={djImageURL} alt="" /> {displayName}
        </div>
      );
    }
  } else if (!isLive) {
    const goLiveClicked = (e) => {
      e.preventDefault();
      goLive()
    }
    goLiveContainer = (
      <div className="go-live">
        <button onClick={goLiveClicked}> GO LIVE </button>
      </div>
    );
  }

  return(
    <div className={`now-playing-container now-playing-${getColor()}`}>
      <div className="left-container">
        { showConnect ? null : trackInfoContainer }
      </div>
      <div className="center-container">
        { showConnect ? trackInfoContainer : playback }
      </div>
      <div className="right-container">
        {djContainer}
        { showConnect ? null : goLiveContainer }
      </div>
    </div>
  );
}

export default NowPlaying;
