import SpotifyWebApi from 'spotify-web-api-js';
import config from '../config';

export default class SpotifyManager {
  static Shared = null;
  static shared() {
    if (SpotifyManager.Shared == null) {
      SpotifyManager.Shared = new SpotifyManager();
    }
    return this.Shared;
  }

  spotifyApi = new SpotifyWebApi();
  playerName = "wefm Web Player";

  webPlaybackInstance = null;
  playerInitialVolume = 1.0;
  playerRefreshRateMs = 1000;

  prevPlayerState = null;

  updatePlayerState = null;
  token = null;

  setAccessToken(token) {
    this.token = token;
    this.spotifyApi.setAccessToken(token)
  }

  async initPlayer() {
    if (this.webPlaybackInstance) { return }

    // Notify the player is loading
    if (this.updatePlayerState) { this.updatePlayerState({spotifyPlayerState: "loading"}) }

    // Wait for Spotify to load player
    await this.waitForSpotify();

    // Setup the instance and the callbacks
    this.setupWebPlaybackEvents();
  }

  waitForSpotify() {
    return new Promise((resolve) => {
      if ('Spotify' in window) { resolve() } 
      else { window.onSpotifyWebPlaybackSDKReady = () => { resolve() } }
    });
  }

  setupWebPlaybackEvents() {
    let { Player } = window.Spotify;

    this.webPlaybackInstance = new Player({
      name: this.playerName,
      volume: this.playerInitialVolume,
      getOAuthToken: (callback) => {
        callback(this.token);
      }
    });

    // Error handling
    this.webPlaybackInstance.addListener('initialization_error', ({ message }) => { console.error("SpotifyInitializationErr: ", message); });
    this.webPlaybackInstance.addListener('authentication_error', ({ message }) => { console.error("SpotifyAuthenticationErr: ", message); });
    this.webPlaybackInstance.addListener('account_error', ({ message }) => { console.error("SpotifyAccountErr: ", message); });
    this.webPlaybackInstance.addListener('playback_error', ({ message }) => { console.error("SpotifyPlaybackErr: ", message); });

    // Playback status updates
    this.webPlaybackInstance.addListener('player_state_changed', (state) => {
      console.log("Playback State: ", state);
      if (this.updatePlayerState) { this.updatePlayerState({ playerState: state, prevPlayerState: this.prevPlayerState }) }
      this.prevPlayerState = state;
    });

    // Ready
    this.webPlaybackInstance.addListener('ready', (data) => {
      console.log('Ready with Device ID', data.device_id);
      if (this.updatePlayerState) {
        this.updatePlayerState({
          spotifyPlayerState: "ready", 
          userDeviceId: data.device_id, 
          isActiveDevice: false,
        })
      }
    });

    // Not Ready
    this.webPlaybackInstance.addListener('not_ready', (data) => {
      console.log('Device ID has gone offline', data.device_id);
    });

    // Connect to the player!
    this.webPlaybackInstance.connect();
  }

  // Device controls

  getCurrentPlaybackState(callback) {
    this.spotifyApi.getMyCurrentPlaybackState()
    .then((response) => {
      if (config.LOG) { console.log("GetCurrentPlaybackResponse: ", response); }
      if (callback) { callback(response, null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("GetCurrentPlaybackError: ", error); }
      if (callback) { callback(null, error); }
    });
  }

  transferPlayback(deviceID, callback) {
    this.spotifyApi.transferMyPlayback([deviceID])
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("TransferError: ", error); }
      if (callback) { callback(error); }
    });
  }

  getDevices(callback) {
    this.spotifyApi.getMyDevices()
    .then((response) => {
      if (config.LOG) { console.log("DevicesResponse: ", response); }
      if (callback) { callback(response, null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("DevicesError: ", error); }
      if (callback) { callback(null, error); }
    });
  }

  // Playback controls

  play(opts, callback) {
    this.spotifyApi.play(opts)
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("PlayError: ", error); }
      if (callback) { callback(error); }
    });
  }

  pause(callback) {
    this.spotifyApi.pause({})
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("PauseError: ", error); }
      if (callback) { callback(error); }
    });
  }

  skipPrevious(callback) {
    this.spotifyApi.skipToPrevious({})
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("PreviousError: ", error); }
      if (callback) { callback(error); }
    });
  }

  skipNext(callback) {
    this.spotifyApi.skipToNext({})
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("NextError: ", error); }
      if (callback) { callback(error); }
    });
  }

  seek(position, callback) {
    this.spotifyApi.seek(position)
    .then(() => {
      if (callback) { callback(null); }
    })
    .catch((error) => {
      if (config.LOG) { console.log("SeekError: ", error); }
      if (callback) { callback(error); }
    });
  }
}
