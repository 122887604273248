import React, {useEffect, useState} from 'react';

import FirebaseManager from '../util/FirebaseManager';
import Station from '../components/Station';

import '../styles/containers/Live.css';

function Live(props) {
  const {tuneIn, connectedStation} = props;

  const [stations, setStations] = useState([]);

  // Code to run on component mount, since we have [] as second arg.
  useEffect(() => {
    FirebaseManager.shared().observeStations((stations) => {
      if (!stations || stations.length === 0) {
        setStations(
          <div className="no-stations">There are no active stations at the time. Start your own by going live!</div>
        );
        return
      }
      var divs = [];
      for (var stationID in stations) {
        if (!stations[stationID]) {
          continue
        }
        divs.push(
          <Station stationID={stationID} info={stations[stationID]} key={stationID} tuneIn={tuneIn} connectedStation={connectedStation} />
        );
      }
      setStations(divs);
    })
    return () => FirebaseManager.shared().detachObserveStations();
  }, [connectedStation]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="live-content">
      <div className="stations-title">LIVE STATIONS</div>
      <div className="flex-grid">
        {stations}
      </div>
    </div>
  );
}

export default Live;
