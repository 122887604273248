import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/analytics";

import LiveManager from './LiveManager.js';
import { removeInactiveStation } from "./wefmapi";
import { trackToFirebaseObj, getPlaceholderAvatarUrl } from "./helpers";

const INACTIVE_TIME_LIMIT = 60000;
const config = {
  apiKey: "AIzaSyA11rDOb5cDy5bfYdRNlQHPblU5HPteN9E",
  authDomain: "wefm-87363.firebaseapp.com",
  databaseURL: "https://wefm-87363.firebaseio.com",
  projectId: "wefm-87363",
  storageBucket: "wefm-87363.appspot.com",
  messagingSenderId: "299113525159",
  appId: "1:299113525159:web:19edb4a9703ec6813c5082",
  measurementId: "G-9VP01NDSXJ",
};
firebase.initializeApp(config);

export default class FirebaseManager {
  static Shared = null;
  static shared() {
    if (FirebaseManager.Shared == null) {
      FirebaseManager.Shared = new FirebaseManager();
    }
    return this.Shared;
  }
  
  auth = firebase.auth();
  db = firebase.database();
  storage = firebase.storage().ref();

  curUserProfile = null;
  lastPlayedTrack = null;

  // ================================ Auth ====================================

  updateFBTable(fbID) {
    if (!fbID) { return }
    this.db.ref("fbTable").child(fbID).set(this.auth.currentUser.uid);
  }

  updateEmail(email) {
    if (!email) { return }
    this.db.ref("users").child(this.auth.currentUser.uid).child("email").set(email)
  }

  updateUserInfo(imgURL) {
    const that = this;
    const currentUser = this.auth.currentUser;

    if (imgURL) {
      let imageRef = this.storage.child(`images/${currentUser.uid}/profile_pic.jpg`)
      fetch(imgURL)
        .then((response) => response.blob())
        .then((data) => {
          if (data) {
            imageRef.put(data).then((snapshot) => {
              snapshot.ref.getDownloadURL().then(function(downloadURL) {
                const splt = downloadURL.split("&token")
                if (splt.length > 0) {
                  that.db.ref("users").child(currentUser.uid).child("imageURL").set(splt[0]);
                }
              });
            })
          }
        })
    }

    const updateData = {};
    if (currentUser.displayName) {
      updateData.displayName = currentUser.displayName;
      let reverseName = currentUser.displayName.split(" ").reverse().join("")
      updateData.searchIndex = {
        "name": currentUser.displayName.toLowerCase(),
        "reversedName": reverseName.toLowerCase(),
      }
    }
    if (currentUser.email) {
      updateData.email = currentUser.email;
    }
    if (imgURL) {
      updateData.imageURL = imgURL;
    }
    this.db.ref("users").child(currentUser.uid).update(updateData);
    this.updateLastActive();
  }

  // ================================ Users ====================================

  getUser(userID, callback) {
    this.db.ref("users").child(userID).on('value', (snapshot => {
      const userData = snapshot.val();
      if (userID === this.auth.currentUser.uid && userData) {
        this.curUserProfile = {
          displayName: userData.displayName,
          ID: userID,
        }
        if (userData.imageURL) {
          this.curUserProfile.imageURL = userData.imageURL;
        } else {
          this.curUserProfile.imageURL = getPlaceholderAvatarUrl();
        }
      }
      callback(userData)
    }));
  }

  // ================================ Initialize ====================================

  monitorCurrentStation(callback) {
    this.db.ref("profiles").child(this.auth.currentUser.uid).child("curStation").on('value', (snapshot) => {
      const val = snapshot.val();
      if (!val) {
        callback(false, "")
        return
      }
      callback(true, val);
    });
  }

  checkForExistingStation(callback) {
    this.db.ref("stations").orderByKey().startAt(this.auth.currentUser.uid).limitToFirst(1).once('value').then((snapshot) => {
      const val = snapshot.val();
      if (!val) {
        callback(false, "")
        return
      }
      for (var id in val) {
        if (id.startsWith(this.auth.currentUser.uid)) {
          callback(true, id)
          return
        }
      }
      callback(false, "")
    });
  }

  checkForAlreadyListened(callback) {
    this.db.ref("profiles").child(this.auth.currentUser.uid).child("curStation").once('value').then((snapshot) => {
      const val = snapshot.val();
      if (!snapshot.exists() || !val) {
        callback(false, "")
        return
      }
      callback(true, val)
    })
  }

  // ================================ Stations ====================================

  observeStations(onUpdate) {
    // TODO: Add limitations and be smart :).
    this.db.ref('stations').on('value', (snapshot) => {
      const snap = snapshot.val();
      const update = {};
      for (var id in snap) {
        const val = snap[id];
        if (!val) {
          continue
        }
        const trackInfo = val.track;
        if (!val.track) { 
          continue
        }
        const diff = Date.now() - (val.lastTrackStarted*1000) - trackInfo.playbackDuration;
        if (diff > INACTIVE_TIME_LIMIT) {
          removeInactiveStation(id);
          continue
        }
        update[id] = val;
      }
      if (onUpdate) {
        onUpdate(update);
      }
    });
  }

  connectToStation(stationID, onUpdate) {
    if (!stationID) { return }
    var prevSnapshot = null;
    this.db.ref("stations").child(stationID).on('value', (snapshot) => {
      if (onUpdate) {
        onUpdate(snapshot, prevSnapshot);
      }
      prevSnapshot = snapshot;
    });
  }

  updateStation(track, curTime) {
    const stationID = LiveManager.shared().myID;
    const uid = this.auth.currentUser.uid;

    if (this.curUserProfile) {
      const data = {
        dj: this.curUserProfile,
        lastTrackStarted: (Date.now()-curTime)/1000,
        track: trackToFirebaseObj(track)
      }
      if (LiveManager.shared().firstLive) {
        data["numListeners"] = 0
        this.db.ref("profiles").child(uid).child("curStation").set(stationID)
      }
      LiveManager.shared().firstLive = false
      this.db.ref("stations").child(stationID).update(data);
      this.updatePreviouslyPlayed(track);
      console.log("Station Updated1", data);
      return
    }
    this.getUser(uid, (userData) => {
      if (!userData) { return }
      const data = {
        dj: {
          displayName: userData.displayName,
          imageURL: userData.imageURL,
          ID: uid
        },
        lastTrackStarted: (Date.now()-curTime)/1000,
        track: trackToFirebaseObj(track)
      }
      if (LiveManager.shared().firstLive) {
        data["numListeners"] = 0
        this.db.ref("profiles").child(uid).child("curStation").set(stationID)
      }
      LiveManager.shared().firstLive = false
      this.db.ref("stations").child(stationID).update(data);
      this.updatePreviouslyPlayed(track);
      console.log("Station Updated2", data);
    })
  }

  updatePreviouslyPlayed(track) {
    if (this.lastPlayedTrack && this.lastPlayedTrack.uri === track.uri) return;
    this.lastPlayedTrack = track;
    this.db.ref("finishedStations").child(LiveManager.shared().myID).child("songs").child(Date.now()).update(trackToFirebaseObj(track));
  }

  disconnectFromStation(stationID) {
    if (!stationID) { return }
    this.db.ref("stations").child(stationID).off('value');
  }

  removeStation(stationID) {
    if (!stationID) { return }
    this.db.ref("stations").child(stationID).remove();
    this.db.ref("listeners").child(stationID).child("active").remove();
    this.db.ref("profiles").child(this.auth.currentUser.uid).child("curStation").remove();
  }

  detachObserveStations() {
    this.db.ref('stations').off('value')
  }

  observeStationChats(stationID, onUpdate) {
    if (!stationID) { return }
    return this.db.ref("chats").child(stationID).limitToLast(100).on('child_added', (snapshot) => {
      if (onUpdate) {
        onUpdate(snapshot);
      }
    })
  }

  detachStationChats(stationID, handle) {
    if (!stationID) { return }
    if (handle) {
      this.db.ref('chats').child(stationID).off('child_added', handle)
    } else {
      this.db.ref('chats').child(stationID).off('child_added')
    }
  }

  sendMessageToLiveStation(stationID, message) {
    if (this.curUserProfile) {
      const data = {
        message: message,
        user: this.curUserProfile,
      };
      this.db.ref("chats").child(stationID).child(`${Date.now()}`).update(data);
      return;
    }
    const uid = this.auth.currentUser.uid;
    this.getUser(uid, (userData) => {
      if (!userData) { return }
      const data = {
        message: message,
        user: {
          displayName: userData.displayName,
          ID: uid
        },
      };
      if (userData.imageURL) {
        data.user.imageURL = userData.imageURL;
      } else {
        data.user.imageURL = getPlaceholderAvatarUrl();
      }
      this.db.ref("chats").child(stationID).child(`${Date.now()}`).update(data);
    })
  }

  // ================================ Listeners ====================================

  observeListeners(stationID, onUpdate) {
    if (!stationID) { return }
    this.db.ref("listeners").child(stationID).child("active").orderByKey().limitToFirst(6).on('value', (snapshot) => {
      if (onUpdate) {
        onUpdate(snapshot.val());
      }
    });
  }

  detachObserveListeners(stationID) {
    if (!stationID) { return }
    this.db.ref("listeners").child(stationID).child("active").off('value')
  }

  addListenerToStation(stationID) {
    if (!stationID) { return }
    this.getUser(this.auth.currentUser.uid, (userData) => {
      if (!userData) { return }
      const data = {
        displayName: userData.displayName,
        ID: this.auth.currentUser.uid,
      };
      if (userData.imageURL) {
        data.imageURL = userData.imageURL;
      } else {
        data.imageURL = getPlaceholderAvatarUrl();
      }
      this.db.ref("listeners").child(stationID).child("active").child(this.auth.currentUser.uid).set(data);
      this.db.ref("profiles").child(this.auth.currentUser.uid).child("curStation").set(stationID);
      this.db.ref("stations").child(stationID).child("numListeners").transaction((count) => {
        if (!count) { return -1 }
        return count-1;
      });
    });
  }

  removeListenerFromStation(stationID) {
    if (!stationID) { return }
    this.db.ref("listeners").child(stationID).child("active").child(this.auth.currentUser.uid).remove();
    this.db.ref("profiles").child(this.auth.currentUser.uid).child("curStation").remove();
    this.db.ref("stations").child(stationID).child("numListeners").transaction((count) => {
      if (!count) { return 0 }
      return count+1;
    });
  }

  // ================================ Log ====================================

  updateLastActive() {
    if (this.auth.currentUser) {
      const lastActive = Date.now()/1000;
      this.db.ref("users").child(this.auth.currentUser.uid).child("lastActive").set(lastActive)
      this.db.ref("users").child(this.auth.currentUser.uid).child("lastWebActive").set(lastActive)
    }
  }

  // type: send or open
  logShareActivity(action, stationID, data) {
    const currentTime = parseInt(Date.now()/1000);
    const values = {
      web: true,
      action: action
    }
    if (stationID) {
      values.type = "inviteToStation"
      values.station = stationID
    }
    this.db.ref("logs").child(this.auth.currentUser.uid).child("share").child(currentTime).update(values)
    this.logEvent("shareExternal", data)
  }

  logAppInteraction(type) {
    const values = {
      version: "web",
      lastActive: Date.now()
    }
    this.logEvent(type, values)
  }

  logEvent(event, params) {
    firebase.analytics().logEvent(event, params)
  }
}
